hr {
  width: calc(min(500px, 90vw));
  z-index: 1;
  border: 2px solid #ffffff10;
  border-radius: 18px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 35px;
  margin-bottom: 35px;
}

h1 {
  font-size: calc(36px + 2vmin);
  margin: 0;
}

h2 {
  font-size: calc(8px + 1vmin);
  margin: 0;
}

.App {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  background-color: #0f0f0f;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  text-decoration: underline;
  color: white;
  transition: all .25s ease-in-out;
}

.App-link:hover, .App-link:focus {
  color: #2d8f9e
}

.App-background {
  max-width: 100vw;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  overflow: hidden !important;
  text-overflow: clip;
}

$particleSize: 20vmin;
$animationDuration: 6s;
$amount: 36;
.App-background span {
  width: $particleSize;
  height: $particleSize;
  border-radius: $particleSize;
  backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-duration: $animationDuration;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  $colors: (
    #7d45fb,
    #0aab91,
    #005f62
  );
  
  @for $i from 1 through $amount {
    &:nth-child(#{$i}) {
      color: nth($colors, random(length($colors)));
      top: random(100) * 1%;
      left: random(100) * 1%;
      animation-duration: (random($animationDuration * 10) / 10) * 1s + 10s;
      animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
      transform-origin: (random(50) - 25) * 1% (random(50) - 25) * 1%;
      $blurRadius: (random() + 0.5) * $particleSize * 0.5;
      $x: if(random() > 0.5, -1, 1);
      box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
    }
  }
}

@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.App-item {
  margin: 18px;
  padding: 24px;
  height: max-content;
  width: 100%;
  font-size: calc(14px + 1.5vmin);
  background-color: #ffffff10;
  color: white;
  text-decoration: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 24px;
  animation: in 1.5s ease-in-out;
}

@keyframes in {
  0% {
    opacity: 0;
    margin-top: 15%;
  }
  100% {
    opacity: 1;
    margin-top: 18px;
  }
}

.App-header {
  width: calc(min(350px, 60vw));
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.header-image {
  width: 80%;
  margin-top: 3%;
  height: auto;
  border-radius: 25%;
  transition: all 1s ease-in-out;
}

.header-title {
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-image[clicked="1"] {
  transform: rotate(-180deg);
}

.header-typewriter {
  height: 2em;
  margin-bottom: 3%;
}

.App-body {
  width: calc(min(500px, 90vw));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.link {
  width: 75%;
  height: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all .25s ease-in-out;
}

.link:hover, .link:focus {
  outline-color: #ffffff60;
  background-color: #ffffff30;
  outline-style: solid;
  transform: scale(1.05);
  margin: 25px;
}

.link-img {
  width: 15%;
  height: auto;
  padding-right: 3vmin;
}

.link-invert {
  filter: invert(100%);
}

.clicked-links {
  animation: in 1.5s ease-in-out;
}

.App-footer {
  width: calc(min(500px, 80vw));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(7px + 1.5vmin);
}

.footer-img {
  margin: 18px;
  transition: all .25s ease-in-out;
}

.footer-img:hover, .footer-img:focus {
  transform: scale(1.1);
}

.footer-img[heart="1"] {
  animation: heart .25s ease-in-out;
}

@keyframes heart {
  50% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1.1);
  }
}